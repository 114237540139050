export const JUPITER_REF = {
  REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3: {
    125: {
      name: 'Initialize Referral Token Account',
      accounts: [
        { name: 'Payer' },
        { name: '' },
        { name: 'Referral' },
        { name: '' },
        { name: 'Mint' },
      ],
      getDataUI: () => {
        return <div></div>
      },
    },
  },
}
