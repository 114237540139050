import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

export function SolanaLogo(props: Props) {
  return (
    <svg
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.933 11.4611L12.787 13.8366C12.7404 13.8882 12.6839 13.9293 12.6212 13.9574C12.5585 13.9855 12.4908 14 12.4224 14H2.24914C2.2006 14 2.15311 13.9854 2.11252 13.9579C2.07193 13.9304 2.04 13.8913 2.02065 13.8454C2.0013 13.7994 1.99538 13.7486 2.00361 13.6993C2.01184 13.6499 2.03387 13.6041 2.06698 13.5674L4.21465 11.192C4.26117 11.1405 4.31744 11.0995 4.37996 11.0714C4.44248 11.0433 4.50993 11.0287 4.57813 11.0286H14.7509C14.7994 11.0286 14.8469 11.0432 14.8875 11.0707C14.9281 11.0982 14.96 11.1373 14.9794 11.1832C14.9987 11.2292 15.0046 11.2799 14.9964 11.3293C14.9882 11.3787 14.9661 11.4245 14.933 11.4611ZM12.787 6.67771C12.7404 6.62611 12.6839 6.58497 12.6212 6.55686C12.5585 6.52874 12.4908 6.51425 12.4224 6.51429H2.24914C2.2006 6.51429 2.15311 6.52892 2.11252 6.5564C2.07193 6.58387 2.04 6.62299 2.02065 6.66893C2.0013 6.71488 1.99538 6.76566 2.00361 6.81504C2.01184 6.86441 2.03387 6.91023 2.06698 6.94686L4.21465 9.32229C4.26117 9.37375 4.31744 9.41481 4.37996 9.44292C4.44248 9.47103 4.50993 9.48559 4.57813 9.48571H14.7509C14.7994 9.48571 14.8469 9.47108 14.8875 9.4436C14.9281 9.41613 14.96 9.37701 14.9794 9.33107C14.9987 9.28512 15.0046 9.23434 14.9964 9.18496C14.9882 9.13559 14.9661 9.08977 14.933 9.05314L12.787 6.67771ZM2.24914 4.97143H12.4224C12.4908 4.97147 12.5585 4.95697 12.6212 4.92886C12.6839 4.90074 12.7404 4.8596 12.787 4.808L14.933 2.43257C14.9661 2.39594 14.9882 2.35012 14.9964 2.30075C15.0046 2.25138 14.9987 2.2006 14.9794 2.15465C14.96 2.1087 14.9281 2.06959 14.8875 2.04211C14.8469 2.01464 14.7994 2 14.7509 2H4.57813C4.50993 2.00012 4.44248 2.01469 4.37996 2.0428C4.31744 2.07091 4.26117 2.11197 4.21465 2.16343L2.06754 4.53886C2.03445 4.57545 2.01244 4.62122 2.00419 4.67054C1.99593 4.71986 2.00181 4.77059 2.02109 4.81652C2.04037 4.86245 2.07222 4.90157 2.11273 4.92908C2.15324 4.9566 2.20065 4.97132 2.24914 4.97143Z"
        fill="url(#paint0_linear_410_6846)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_410_6846"
          x1="3.09735"
          y1="14.286"
          x2="14.1443"
          y2="2.22017"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#9945FF" />
          <stop offset="0.3" stopColor="#8752F3" />
          <stop offset="0.5" stopColor="#5497D5" />
          <stop offset="0.6" stopColor="#43B4CA" />
          <stop offset="0.72" stopColor="#28E0B9" />
          <stop offset="0.97" stopColor="#19FB9B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
